import { Injectable } from '@angular/core';
import { OclEventGroup } from '../../models/ocl-event-group.model';
import { OclLogBookGroup } from '../../models/ocl-logbook-group.model';
import { OclEvent } from '../../models/ocl-event.model';
import { OclDecisionGroup } from '../../models/ocl-decision-group.model';
import { OclDecision } from '../../models/ocl-decision.model';
import { OclLogbook } from '../../models/ocl-logbook.model';
import { RequestService } from '../../../common/services/request.service';
import { OclGlobalInstructionGroup } from '../../models/ocl-global-instruction-group.model';
import { OclGroupsStoreManager } from '../../store/groups/ocl-groups.store-manager';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { OclGroup } from '../../models/ocl-group.model';

export type OclGroupType = 'EVENT' | 'LOGBOOK' | 'DECISION' | 'GLOBAL_INSTRUCTION';

interface GroupTypeMappings {
  storeType: 'events' | 'logbooks' | 'decisions' | 'globalInstructions';
  fieldName: string;
  parseGroup: any;
  parseObject: any;
}

@Injectable({
  providedIn: 'root',
})
export abstract class OclGroupService {
  // tslint:disable:variable-name
  protected ParseEventGroup;
  protected ParseDecisionGroup;
  protected ParseLogbookGroup;
  protected ParseGlobalInstructionGroup;
  protected ParseEvent;
  protected ParseDecisions;
  protected ParseLogbook;
  protected ParseGlobalInstruction;

  // tslint:enable

  protected constructor(
    protected requestService: RequestService,
    protected groupStoreManager: OclGroupsStoreManager,
    protected moduleConfig: ModuleConfigService,
  ) {}

  protected get groupTypeMap(): Map<OclGroupType, GroupTypeMappings> {
    return new Map<OclGroupType, GroupTypeMappings>([
      [
        'EVENT',
        {
          storeType: 'events',
          fieldName: 'events',
          parseGroup: this.ParseEventGroup,
          parseObject: this.ParseEvent,
        },
      ],
      [
        'LOGBOOK',
        {
          storeType: 'logbooks',
          fieldName: 'logBooks',
          parseGroup: this.ParseLogbookGroup,
          parseObject: this.ParseLogbook,
        },
      ],
      [
        'DECISION',
        {
          storeType: 'decisions',
          fieldName: 'decisions',
          parseGroup: this.ParseDecisionGroup,
          parseObject: this.ParseDecisions,
        },
      ],
      [
        'GLOBAL_INSTRUCTION',
        {
          storeType: 'globalInstructions',
          fieldName: 'globalInstructions',
          parseGroup: this.ParseGlobalInstructionGroup,
          parseObject: this.ParseGlobalInstruction,
        },
      ],
    ]);
  }

  save(group: OclGroup<any>, type: OclGroupType): Promise<OclGroup<any>> {
    const { parseGroup, parseObject, fieldName } = this.groupTypeMap.get(type);
    const parseGroupObject = new parseGroup();
    if (group.objectId) {
      // updateMode
      parseGroupObject.id = group.objectId;
    }

    parseGroupObject.set('createdBy', Parse.User.current());
    parseGroupObject.set('title', group.title);

    const parseItems = group.items.map(el => {
      return new parseObject({ id: el.objectId });
    });
    parseGroupObject.set(fieldName, parseItems);

    this.setAdditionalFields(parseGroupObject, group, type);

    return new Promise((resolve, reject) => {
      this.requestService.performSaveQuery(
        parseGroupObject,
        null,
        parseData => resolve(this.newItem(type, parseData)),
        error => reject(error),
      );
    });
  }

  delete(group: OclGroup<any>, type: OclGroupType): Promise<void> {
    const { parseGroup } = this.groupTypeMap.get(type);
    const parseGroupObject = new parseGroup();
    parseGroupObject.id = group.objectId;

    return new Promise((resolve, reject) => {
      return this.requestService.performDestroyQuery(
        parseGroupObject,
        () => {
          resolve();
        },
        error => {
          reject(error);
        },
      );
    });
  }

  async getAll(type: OclGroupType, isFromPolling = false): Promise<any[]> {
    const { parseGroup, storeType } = this.groupTypeMap.get(type);
    let query = new Parse.Query(parseGroup);
    query.descending('createdAt');
    query = this.setAdditionalQueryFilters(query);

    return this.requestService.performFindQuery(query).then(results => {
      const groups = results.map(result => this.newItem(type, result));
      if (isFromPolling) {
        this.groupStoreManager.updateGroupsFromPooling(groups, storeType, this.moduleConfig.config.moduleName);
      } else {
        this.groupStoreManager.initGroups(groups, storeType);
      }
      return groups;
    });
  }

  async deleteLogbookInGroup(objectId: string): Promise<any> {
    const { parseGroup } = this.groupTypeMap.get('LOGBOOK');
    const query = new Parse.Query(parseGroup);
    query.equalTo('logBooks.objectId', objectId);

    const parseGroups = await this.requestService.performFindQuery(query);
    const group = parseGroups.map(result => this.newItem('LOGBOOK', result))[0];
    if (group) {
      group.items = group.items.filter(l => l.objectId !== objectId);
      await this.save(group, 'LOGBOOK');
    }
  }

  async deleteDecisionInGroup(objectId: string): Promise<any> {
    const { parseGroup } = this.groupTypeMap.get('DECISION');
    const query = new Parse.Query(parseGroup);
    query.equalTo('decisions.objectId', objectId);

    const parseGroups = await this.requestService.performFindQuery(query);
    const group = parseGroups.map(result => this.newItem('DECISION', result))[0];
    if (group) {
      group.items = group.items.filter(l => l.objectId !== objectId);
      await this.save(group, 'DECISION');
    }
  }

  generateGroupAvailable(group, data): any[] {
    // je retire les items non affichés sur le dashboard et je map le reste
    const grop = group.length ? group : [group]; //problème causé dans le cas d'un seul groupe, traité comme array mais c un objet
    try {
      return grop.filter(grp => {
        grp.items = grp.items
          .filter(itemInGroup => {
            return data.findIndex(el => el.objectId === itemInGroup.objectId) !== -1;
          })
          .map(itemInGroup => data.find((el: OclEvent | OclLogbook | OclDecision) => el.objectId === itemInGroup.objectId));
        return grp.items.length;
      });
    } catch (e) {
      return grop;
    }
  }

  generateGroupForDisplay(group, data, type): any[] {
    let dataGrouped: any[] = [];
    dataGrouped = [
      ...data
        .map(el => {
          // tslint:disable-next-line: max-line-length
          //  si un item n'est pas présent dans un group alors il sera taggé avec ALWAYS_VISIBLE car on pourra le voir dans les 2 vue (group et non group) et n'aura pas de group assigné
          // tslint:disable-next-line: max-line-length
          // si un item est présent dans un group alors il sera taggé avec NOT_VISIBLE_IN_GROUP_VIEW car on ne pourra le voir que si on est en vue non group et n'aura pas de group assigné
          const itemNotFound = group.every(grp => {
            return grp.items.findIndex(itemInGroup => itemInGroup.objectId === el.objectId) === -1;
          });
          const bufferGroup = this.newItem(type);

          bufferGroup.displayInGroupViewStatus = !itemNotFound ? 'NOT_VISIBLE_IN_GROUP_VIEW' : 'ALWAYS_VISIBLE';
          bufferGroup.items = [el];
          return bufferGroup;
        })
        .filter(el => el !== undefined),
      ...group,
    ];
    return dataGrouped;
  }

  fetchNewDataEventGroup() {
    return this.getAll('EVENT', true);
  }

  fetchNewDataLogBookGroup() {
    return this.getAll('LOGBOOK', true);
  }

  fetchNewDataDecisionGroup() {
    return this.getAll('DECISION', true);
  }

  fetchNewDataGlobalInstructionGroup() {
    return this.getAll('GLOBAL_INSTRUCTION', true);
  }

  protected setAdditionalFields(parseGroup: Parse.Object, group: OclGroup<any>, type: OclGroupType) {
    return;
  }

  protected setAdditionalQueryFilters(query: Parse.Query): Parse.Query {
    return query;
  }

  protected newItem(type: OclGroupType, parseData?: Parse.Object): OclGroup<any> {
    switch (type) {
      case 'EVENT':
        return new OclEventGroup(parseData);
      case 'LOGBOOK':
        return new OclLogBookGroup(parseData);
      case 'DECISION':
        return new OclDecisionGroup(parseData);
      case 'GLOBAL_INSTRUCTION':
        return new OclGlobalInstructionGroup(parseData);
    }
    return null;
  }
}
