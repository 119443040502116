import { UserFailingToLoginService } from './user-failling-to-login.service';
import { Inject, Injectable, Optional } from '@angular/core';
import { forkJoin } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { OptionsService } from 'src/app/common/services/options.service';
import * as Raven from 'raven-js';
import { LocalStorageService } from 'src/app/common/services/local-storage.service';
import { AlertModalComponent } from 'src/app/common/modals/alert-modal/alert-modal.component';
import { UserService } from 'src/app/common/services/user.service';

import { MsalService } from '@azure/msal-angular';

import { USER_RIGHTS } from '../../app.module';
import { HolCrisis } from '../../common/models/hol-crisis';
import { CrisisService } from '../../common/services/crisis/crisis.service';
import { CurrentUserService } from '../../common/services/current-user.service';
import { FunctionsService } from '../../common/services/functions.service';
import { HolFunctionService } from '../../common/services/hol-function.service';
import { HolOptionsService } from '../../common/services/hol-options.service';
import { HolUserFunctionService } from '../../common/services/hol-user-function.service';
import { PollingService } from '../../common/services/polling/polling.service';
import { RequestService } from '../../common/services/request.service';
import { RolesService } from '../../common/services/roles.service';
import { CommonStoreManager } from '../../common/store/common.store-manager';
import { ErpHistoryLog } from '../../erp/models/erp-historyLog';
import { ErpHistoryService } from '../../erp/services/erp-history.service';
import { ErpUsersService } from '../../erp/services/erp-users.service';
import { APP_MSAL_CONFIG, MsalConfiguration } from '../../msal/hol-msal.model';
import { OpsOptionsService } from '../../ops/services/ops-options-service/ops-options.service';
import { AccountInfo } from '@azure/msal-browser';
import { EclCrisisService } from '../../ecl/services/ecl-crisis-service/ecl-crisis.service';
import { EclAnnoucementService } from 'src/app/ecl/services/ecl-annoucement.service';
import { EclFunctionStoreManager } from '../../ecl/store/function/function.store-manager';
import { TranslatePipe } from '../../common/pipes/translate/translate.pipe';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    @Inject('$state') private $state,
    @Inject('$translate') private $translate,
    private readonly userService: UserService,
    private currentUserService: CurrentUserService,
    private optionsService: OptionsService,
    private holOptionsService: HolOptionsService,
    private opsOptionsService: OpsOptionsService,
    @Inject('ChatService') private chatService,
    private poolService: PollingService,
    @Inject('CONSTANTS') private CONSTANTS,
    @Inject('$rootScope') private $rootScope,
    private readonly localStorage: LocalStorageService,
    private requestService: RequestService,
    private readonly dialog: MatDialog,
    private rolesService: RolesService,
    private crisisService: CrisisService,
    private eclAnnouncementService: EclAnnoucementService,
    private eclCrisisService: EclCrisisService,
    private erpHistoryService: ErpHistoryService,
    private functionsService: FunctionsService,
    private eclFunctionStoreManager: EclFunctionStoreManager,
    private erpUsersService: ErpUsersService,
    private commonStoreManager: CommonStoreManager,
    private holFunctionService: HolFunctionService,
    private holUserFunctionService: HolUserFunctionService,
    @Optional() private msalService: MsalService,
    @Inject(APP_MSAL_CONFIG) private readonly msalConfiguration: MsalConfiguration,
    protected translatePipe: TranslatePipe,
    private userFailingToLoginService: UserFailingToLoginService,
  ) {}

  public tryLogin(): Promise<Parse.User> {
    const currentUser = Parse.User.current();

    // Check if SSO is activated
    const ssoActivated = this.msalConfiguration.isMsalActivated;
    const msalAccount = this.getMicrosoftAccount();
    const localStorageMsalAccount = JSON.parse(localStorage.getItem('msal_account'));

    // And check if connexion still valid
    if (ssoActivated) {
      if (
        msalAccount !== null &&
        localStorageMsalAccount !== null &&
        currentUser &&
        msalAccount.username === currentUser.get('username') &&
        localStorageMsalAccount &&
        localStorageMsalAccount.username === msalAccount.username &&
        localStorageMsalAccount.localAccountId === msalAccount.localAccountId &&
        localStorageMsalAccount.isSSOUser
      ) {
        // And check if session still valid
        return Parse.User.become(currentUser.getSessionToken()).then((user: Parse.User) => {
          return user;
        });
      } else if (currentUser && localStorageMsalAccount && !localStorageMsalAccount.isSSOUser) {
        return Parse.User.become(currentUser.getSessionToken()).then((user: Parse.User) => {
          return user;
        });
      } else {
        return this.microsoftLogin().then(() => {
          const newCurrentUser = Parse.User.current();
          if (newCurrentUser) {
            return Parse.User.become(newCurrentUser.getSessionToken()).then((user: Parse.User) => {
              return user;
            });
          } else {
            localStorage.removeItem('msal_account');
            this.msalService.instance.setActiveAccount(null);
            return Promise.reject(null);
          }
        });
      }
    } else {
      // And check if session still valid
      if (currentUser) {
        return Parse.User.become(currentUser.getSessionToken()).then((user: Parse.User) => {
          return user;
        });
      }
    }
    return Promise.reject(null);
  }

  public login(username, password, isMicrosoftAuthActivated = false): Promise<Parse.User> {
    return this.requestService
      .performCloudCode<Parse.User>('authentication', { username, password })
      .then(async u => {
        const token = u.getSessionToken();
        await Parse.User.become(token);
        const log = new ErpHistoryLog();
        log.type = 'Login';
        log.hideInHistory = true;
        this.erpHistoryService.postLog(log);

        if (isMicrosoftAuthActivated) {
          localStorage.setItem(
            'msal_account',
            JSON.stringify({
              isSSOUser: false,
            }),
          );
        }

        if (!u.get('isBlacklisted')) {
          this.userFailingToLoginService.clearUserFailingToLogin(username);
        }
        return u;
      })
      .catch(error => {
        throw error;
      });
  }

  public async initApp(parseUser: Parse.User): Promise<void> {
    // eslint-disable-next-line no-restricted-syntax
    console.time('*** APP INITIALIZATION ***');

    // Init raven
    Raven.setUserContext({
      email: parseUser.get('email'),
      id: parseUser.get('userId'),
    });

    let user = await this.currentUserService.initCurrentUser();
    // check blacklist
    if (user && user.isBlacklisted) {
      throw this.$translate.instant('LOGIN.ERROR.BLACKLISTED_USER');
    }
    this.$translate.onReady();

    // Options
    const [options, holOptions, opsOptions] = await Promise.all([
      this.optionsService.get(),
      this.holOptionsService.get(),
      this.opsOptionsService.get(true),
    ]);
    if (options.env === 'sandbox') {
      document.title = this.translatePipe.transform('INITAPP.MODALS.AUTH.DOCTITLE', {
        company: this.CONSTANTS.COMPANY_NAME,
      });
      this.dialog.open(AlertModalComponent, {
        data: {
          modalTitle: this.translatePipe.transform('INITAPP.MODALS.AUTH.TITLE'),
          modalContent: this.translatePipe.transform('INITAPP.MODALS.AUTH.CONTENT'),
          modalType: 'info',
        },
      });
    }
    // Access rights
    const accessRights = await this.userService.getAccessRights(true);
    this.$rootScope.accessRights = accessRights;

    // Get roles (for ACL filter and other topics)
    const [userRoles, allRoles] = await forkJoin([this.rolesService.getCurrentUserRoles(), this.rolesService.getAll()]).toPromise();

    // Connect to chat
    const isInTeam = await this.userService.isCurrentUserInTheChatTeam();
    if (isInTeam) {
      this.chatService.connect();
    }

    // Initialize crisis (ERP and ECL)
    // Initialize HolFunctions
    const [erpCrisisRights, erpCrisis, eclCrisis, functions, userFunctions, lastCrisis] = await Promise.all([
      this.functionsService.getCurrentUserCrisisRightsRef(),
      this.crisisService.get().catch(() => null),
      this.eclCrisisService.getCurrentOrLastCrisis(),
      this.holFunctionService.getAll(),
      this.holUserFunctionService.getAll(),
      this.eclCrisisService.getLastCrisis(),
    ]);
    this.commonStoreManager.initErpCrisis(erpCrisis);
    this.commonStoreManager.initEclCrisis(eclCrisis);
    this.commonStoreManager.initLastEclCrisis(lastCrisis);
    this.commonStoreManager.initHolFunctions(functions);
    this.commonStoreManager.initHolUserFunctions(userFunctions);
    // Important: ECL crisisRolesRef now is determined from Managers & HolFunctions.
    // It's no more used, but we have to initialize them to avoid undefined values
    this.commonStoreManager.initCrisisRolesRef(erpCrisisRights, {
      isInCrisisInitializerTeam: false,
      isInCrisisDirectorTeam: false,
    });

    user = await this.currentUserService.justLoggedIn();
    // eslint-disable-next-line no-restricted-syntax
    console.timeEnd('*** APP INITIALIZATION ***');

    // Run pooling
    this.poolService.startLongPooling();
  }

  public enterApp(): Promise<any> {
    // TODO Check isExternal ?
    return this.optionsService
      .get()
      .then(() => {
        return Promise.all([
          this.userService.getAccessRights(true),
          this.crisisService.get().catch(() => {
            return null;
          }),
        ]);
      })
      .then(([accessRights, crisis]: [any, HolCrisis]) => {
        // If a crisis is in progress, got to crisis dashboard
        if (crisis && crisis.inProgress && accessRights.crisis !== USER_RIGHTS.UNAUTHORIZED) {
          return this.$state.go('app.crisis.dashboard');
        } else {
          // Get last location from storage and go to it
          const urlLocation = this.localStorage.getUrlLocation();
          const urlParams = this.localStorage.getUrlParams();
          if (urlLocation) {
            return this.$state.go(urlLocation, urlParams).catch(() => {
              this.localStorage.setUrlLocation(null);
              this.localStorage.setUrlParams(null);
              return this.$state.go(this.getDefaultPage(accessRights));
            });
          } else {
            // Go to default page depending of the accessRights
            return this.$state.go(this.getDefaultPage(accessRights));
          }
        }
      })
      .catch(() => this.isAdmin().then(() => this.$state.go('app.admin.dashboard')));
  }

  public logout(resetHolderFlags: boolean): Promise<void> {
    const user = Parse.User.current();
    this.poolService.stopIt();
    const log = new ErpHistoryLog();
    log.type = 'Logout';
    log.hideInHistory = true;
    this.erpHistoryService.postLog(log);
    this.userService.clearCache();
    if (localStorage.getItem('msal_account') !== null) {
      localStorage.removeItem('msal_account');
      this.msalService.logout();
    }

    // Check if SSO is activated
    const ssoActivated = this.msalConfiguration.isMsalActivated;
    let msalLoggedIn = false;
    let msalCurrentAccount = null;
    if (ssoActivated) {
      msalCurrentAccount = this.getMicrosoftAccount();
      msalLoggedIn = msalCurrentAccount !== null;
    }

    return new Promise<any>((resolve, reject) => {
      if (user && resetHolderFlags) {
        return this.erpUsersService.resetOnDutyForAllFunctions().then(resolve, reject);
      } else {
        return resolve(null);
      }
    })
      .then(() => {
        return new Promise<void>((resolve, reject) => {
          if (user) {
            return this.requestService.performCloudCode('disconnectUser', { objectId: user.id }, resolve, reject);
          } else {
            return resolve();
          }
        });
      })
      .then(() => {
        return Parse.User.logOut().then(() => {
          if (ssoActivated && msalLoggedIn) {
            localStorage.removeItem('msal_account');
            this.msalService.instance.setActiveAccount(null);
          }
          this.$state.go('login');
        });
      })
      .catch(() => {
        if (ssoActivated && msalLoggedIn) {
          localStorage.removeItem('msal_account');
          this.msalService.instance.setActiveAccount(null);
        }
        this.$state.go('login');
      });
  }

  /***Check if string contains HTML/JS code */
  containsCode(str: string): boolean[] {
    const verifExpressions = [];
    ['<(.)>.?|<(.) />', '<(S?)[^>]>.?|<.*?/>', "<[a-zA-Z]+(s+[a-zA-Z]+s*=s*(“([^”])”|'([^’])’))s/>"].forEach(pattern => {
      const re = new RegExp(pattern);
      verifExpressions.push(re.test(str));
    });
    return verifExpressions.filter(item => item === true);
  }

  public resetPassword(username): Promise<{ sendByMail: boolean; sendBySms: boolean }> {
    // console.log("ISCode",this.containsCode("<a href='' />").length>0);
    if (this.containsCode(this.CONSTANTS.COMPANY_NAME).length > 0) {
      this.dialog.open(AlertModalComponent, {
        data: {
          modalTitle: this.translatePipe.transform('ERP.PASSWORDRESET.MODAL.TITLE'),
          modalContent: this.translatePipe.transform('ERP.PASSWORDRESET.MODAL.CONTENT'),
          modalType: 'info',
        },
      });
    } else {
      // Call logout to force cleaning saved session tokens
      Parse.User.logOut();
      return this.requestService.performCloudCode('resetPassword', {
        username,
        location: location.origin,
        sender: this.CONSTANTS.COMPANY_NAME,
      });
    }
  }

  public isAdmin(throwError = true): Promise<boolean> {
    const query = new Parse.Query(Parse.Role).equalTo('users', Parse.User.current()).equalTo('name', 'Admin');
    return this.requestService
      .performCountQuery(query)
      .then(count => {
        if (count >= 1) {
          return true;
        }
        if (!throwError) {
          return false;
        }
        throw new Error('Unauthorized');
      })
      .catch(() => {
        throw new Error('Unauthorized');
      });
  }

  public getMicrosoftAccount(): AccountInfo | null {
    return this.msalService.instance.getActiveAccount();
  }

  public isMicrosoftAccount(): boolean {
    return this.getMicrosoftAccount() !== null;
  }

  public isUsernameForSSOConnection(username: string): Promise<boolean> {
    return this.holOptionsService.getDomains().then(domains => {
      const domain = username.split('@').pop();
      return domains.indexOf(domain) !== -1;
    });
  }

  public microsoftLogin(): Promise<Parse.User> {
    const localStorageMsalAccount = JSON.parse(localStorage.getItem('msal_account'));
    const account = this.getMicrosoftAccount();
    const provider = 'microsoft';
    let authData = {};
    if (
      account &&
      localStorageMsalAccount &&
      localStorageMsalAccount.username === account.username &&
      localStorageMsalAccount.localAccountId === account.localAccountId
    ) {
      authData = {
        id: localStorageMsalAccount.localAccountId,
        access_token: localStorageMsalAccount.accessToken,
        mail: localStorageMsalAccount.username,
      };
      return this.requestService
        .performCloudCode<Parse.User>('linkWithAccount', {
          provider: 'microsoft',
          authData: {
            id: localStorageMsalAccount.localAccountId,
            access_token: localStorageMsalAccount.accessToken,
            mail: localStorageMsalAccount.username,
          },
        })
        .then(async (user: Parse.User) => {
          const token = user.getSessionToken();
          const linkedUser: Parse.User = await user.linkWith(
            provider,
            { authData: authData },
            {
              sessionToken: token,
            },
          );
          const linkedUserToken = linkedUser.getSessionToken();
          if (linkedUser._isLinked(provider)) {
            console.log('Success!', `User ${linkedUser.get('username')} has successfully signed in!`);
            if (linkedUserToken) {
              const linkedUserLogged: Parse.User = await Parse.User.become(linkedUserToken);
              const log = new ErpHistoryLog();
              log.type = 'Login Microsoft';
              log.hideInHistory = true;
              this.erpHistoryService.postLog(log);
              return Promise.resolve(linkedUserLogged);
            } else {
              console.log(
                this.translatePipe.transform('INITAPP.ERROR.UNAUTHORIZED', {
                  provider: provider,
                }),
              );
              throw new Error(
                this.translatePipe.transform('INITAPP.ERROR.UNAUTHORIZED', {
                  provider: provider,
                }),
              );
            }
          } else {
            console.log(
              this.translatePipe.transform('INITAPP.ERROR.UNAUTHORIZED', {
                provider: provider,
              }),
            );
            throw new Error(
              this.translatePipe.transform('INITAPP.ERROR.UNAUTHORIZED', {
                provider: provider,
              }),
            );
          }
        })
        .catch(e => {
          console.log('e', e);
          throw new Error(e);
        });
    }
    return Promise.reject(null);
  }

  private getDefaultPage(accessRights) {
    if (accessRights.occ !== USER_RIGHTS.UNAUTHORIZED) {
      return 'app.occ.dashboard';
    } else if (accessRights.ecl !== USER_RIGHTS.UNAUTHORIZED) {
      return 'app.ecl.dashboard';
    } else if (accessRights.crew !== USER_RIGHTS.UNAUTHORIZED) {
      return 'app.crew.dashboard';
    } else if (accessRights.mcc !== USER_RIGHTS.UNAUTHORIZED) {
      return 'app.mcc.dashboard';
    } else if (accessRights.goc !== USER_RIGHTS.UNAUTHORIZED) {
      return 'app.goc.dashboard';
    } else if (accessRights.crisis !== USER_RIGHTS.UNAUTHORIZED) {
      return 'app.crisis.dashboard';
    } else if (this.isAdmin()) {
      return 'app.admin.dashboard';
    } else {
      return 'app.crisis.dashboard';
    }
  }
}
