import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { HolOptions } from '../models/hol-options';

export class HOLOptions {
  functionIdsForOCCManagers: string[];
  functionIdsForECLManagers: string[];
  functionIdsForGOCManagers: string[];
  functionIdsForOPSManagers: string[];
  functionIdsForCREWManagers: string[];
  functionIdsForMCCManagers: string[];
  messageMaxLength: number;
  nextInfoLimit: number;
  fieldDataLimit: number;
  domains: string[];
  hideAttachmentLinksOnEmails = false;
  passwordLength: number;
  passwordExpiredInDay: number;
  passwordSentBySMS = false;
  objectId: string;
  activeModules: string[];
  showPasswordAdminPage: boolean = false;
  defaultTimeMinuteForChangeCustomCreateDate?: number;
}

@Injectable({
  providedIn: 'root',
})
export class HolOptionsService {
  // tslint:disable-next-line:variable-name
  protected ParseOptions = Parse.Object.extend('HOLOptions');
  private options: HOLOptions; // @cache

  public constructor(private requestService: RequestService) {}

  // @hasCache options
  get(forceRefresh?): Promise<HOLOptions> {
    if (this.options && !forceRefresh) {
      return Promise.resolve(this.options);
    } else {
      const query = new Parse.Query(this.ParseOptions);
      return this.requestService.performFirstQuery(query).then(result => {
        if (result) {
          this.options = {
            functionIdsForOCCManagers: result.get('functionIdsForOCCManagers') ? result.get('functionIdsForOCCManagers').split('|') : [],
            functionIdsForECLManagers: result.get('functionIdsForECLManagers') ? result.get('functionIdsForECLManagers').split('|') : [],
            functionIdsForGOCManagers: result.get('functionIdsForGOCManagers') ? result.get('functionIdsForGOCManagers').split('|') : [],
            functionIdsForOPSManagers: result.get('functionIdsForOPSManagers') ? result.get('functionIdsForOPSManagers').split('|') : [],
            functionIdsForCREWManagers: result.get('functionIdsForCREWManagers') ? result.get('functionIdsForCREWManagers').split('|') : [],
            functionIdsForMCCManagers: result.get('functionIdsForMCCManagers') ? result.get('functionIdsForMCCManagers').split('|') : [],
            domains: result.get('domains') ? result.get('domains').split('|') : [],
            messageMaxLength: result.get('messageMaxLength') ? result.get('messageMaxLength') : 160,
            nextInfoLimit: result.get('nextInfoLimit') ? result.get('nextInfoLimit') : 160,
            fieldDataLimit: result.get('fieldDataLimit') ? result.get('fieldDataLimit') : 160,
            hideAttachmentLinksOnEmails: !!result.get('hideAttachmentLinksOnEmails'),
            passwordLength: result.get('passwordLength') ? result.get('passwordLength') : 6,
            passwordExpiredInDay: result.get('passwordExpiredInDay') ? result.get('passwordExpiredInDay') : 0,
            passwordSentBySMS: !!result.get('passwordSentBySMS'),
            objectId: result.id,
            activeModules: result.get('activeModules'),
            showPasswordAdminPage: result.get('showPasswordAdminPage') ? result.get('showPasswordAdminPage') : false,
            defaultTimeMinuteForChangeCustomCreateDate: result.get('defaultTimeMinuteForChangeCustomCreateDate')
              ? result.get('defaultTimeMinuteForChangeCustomCreateDate')
              : null,
          };
        } else {
          this.options = new HOLOptions();
        }
        return this.options;
      });
    }
  }

  async updateOptionsFunction(options: HolOptions): Promise<HolOptions> {
    const parseOptions = new this.ParseOptions({ id: options.objectId });
    parseOptions.set('passwordLength', +options.passwordLength);
    parseOptions.set('passwordExpiredInDay', +options.passwordExpiredInDay);
    parseOptions.set('passwordSentBySMS', options.passwordSentBySMS);
    parseOptions.set('hideAttachmentLinksOnEmails', options.hideAttachmentLinksOnEmails);
    parseOptions.set('messageMaxLength', +options.messageMaxLength);
    parseOptions.set('nextInfoLimit', +options.nextInfoLimit);
    parseOptions.set('fieldDataLimit', +options.fieldDataLimit);
    parseOptions.set('functionIdsForECLManagers', options.functionIdsForECLManagers.join('|'));
    return this.requestService.performSaveQuery(parseOptions);
  }

  getFunctionsIdsForManagerByModule(module: string): string[] {
    if (this.options) {
      switch (module.toUpperCase()) {
        case 'OCC':
          return this.options.functionIdsForOCCManagers;
        case 'ECL':
          return this.options.functionIdsForECLManagers;
        case 'GOC':
          return this.options.functionIdsForGOCManagers;
        case 'OPS':
          return this.options.functionIdsForOPSManagers;
        case 'MCC':
          return this.options.functionIdsForMCCManagers;
        case 'CREW':
          return this.options.functionIdsForCREWManagers;
      }
    }
    return [];
  }

  getDomains(): Promise<string[]> {
    let domains: string[] = [];
    const query = new Parse.Query(this.ParseOptions);
    return this.requestService.performFirstQuery(query).then(result => {
      if (result) {
        domains = result.get('domains') ? result.get('domains').split('|') : [];
      }
      return domains;
    });
  }

  getHideAttachmentLinksOnEmails(): boolean {
    return !!(this.options && this.options.hideAttachmentLinksOnEmails);
  }

  isDefaultTimeMinuteForChangeCustomCreateDate(): boolean {
    return !!(this.options && this.options.defaultTimeMinuteForChangeCustomCreateDate);
  }

  getDefaultTimeMinuteForChangeCustomCreateDate(): number {
    return this.options.defaultTimeMinuteForChangeCustomCreateDate | 0;
  }
}
