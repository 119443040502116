import { ComponentType } from '@angular/cdk/portal';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';

import { cloneDeep } from 'lodash';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { HolObject } from 'src/app/common/models/hol-object';

import { nextInfoType } from '../../components/hol-next-info/hol-next-info.component';
import { HolNotification, HolNotifyFunction } from '../../models/hol-notification.model';
import { DialogHistoryData, HolHistoryItemsModalComponent } from '../hol-history-items-modal/hol-history-items-modal.component';
import { HolTag } from '../../models/hol-tag';
import { MatDialogRef } from '@angular/material/dialog';
import { ErpCrisisTask } from '../../../erp/models/erp-crisisTask';
import { HOLOptions, HolOptionsService } from '../../services/hol-options.service';
import {
  ButtonConfig,
  ButtonParameter,
  ButtonToggle,
  ButtonType,
  HolModalConfig,
  HolModalConfigBuilder,
  modalButtonConfig,
  ModalType,
  ModuleType,
} from './config/HolModalConfig';
import { groupBy } from '../../utils/map-utils';
import {
  Done,
  InSummary,
  Internal,
  isDone,
  isInSummary,
  isInternal,
  isIsPinned,
  IsPinned,
  isToErp,
  ToErp,
} from '../../../ocl/models/ocl-interface.model';
import { HolAttachments } from '../../models/hol-attachments.model';
import { CurrentUserService } from '../../services/current-user.service';
import { Observable } from 'rxjs';
import { OclDecision } from '../../../ocl/models/ocl-decision.model';

@Component({
  selector: 'app-hol-modal',
  templateUrl: './hol-modal.component.html',
  styleUrls: ['./hol-modal.component.scss'],
})
export class HolModalComponent implements OnInit, OnDestroy {
  //New
  @Input() config: HolModalConfig;
  public groupButton: Map<ButtonType, ButtonConfig[]> = new Map();
  public keyButton: Map<string, ButtonConfig> = new Map();
  public sections: SectionConfig[] = [];
  public actionMode: boolean = false;
  @ViewChild('template_TAG', { static: true, read: TemplateRef }) templateTAG: TemplateRef<any>;
  @ViewChild('template_ATTACHMENTS', { static: true, read: TemplateRef }) templateATTAMENTS: TemplateRef<any>;
  @ViewChild('template_GROUP_LINK', { static: true, read: TemplateRef }) templateGROUP_LINK: TemplateRef<any>;
  @ViewChild('template_GROUP_DECISION', { static: true, read: TemplateRef }) templateDECISION_LINK: TemplateRef<any>;
  @ViewChild('template_FLIGHT_LINK', { static: true, read: TemplateRef }) templateFLIGHT_LINK: TemplateRef<any>;
  @ViewChild('template_EVENT_LINK', { static: true, read: TemplateRef }) templateEVENT_LINK: TemplateRef<any>;
  @ViewChild('template_LOGBOOK_LINK', { static: true, read: TemplateRef }) templateLOGBOOK_LINK: TemplateRef<any>;
  @ViewChild('template_APPLICABILITY', { static: true, read: TemplateRef }) templateAPPLICABILITY: TemplateRef<any>;
  @ViewChild('template_TIMER_ALERT', { static: true, read: TemplateRef }) templateTIMER_ALERT: TemplateRef<any>;
  @ViewChild('template_MAKE_TODO', { static: true, read: TemplateRef }) templateMAKE_TODO: TemplateRef<any>;
  @ViewChild('NOT_IMPLEMENTED', { static: true, read: TemplateRef }) workInProgress: TemplateRef<any>;
  //Old , nead to migrate
  @Input() item: HolObject | ErpCrisisTask;
  @Input() isReadOnly = false;
  @Input() isCreate = true;
  @Input() type: string;
  @Input() form: FormGroup;
  @Input() contentTextLength = 160;
  @Input() limitTo: HolObject;
  @Input() context: {
    module: string;
    category: string;
    htmlTitle: string;
    htmlDate: string;
    htmlTemplate: string;
  };
  @Input() canArchive = false;
  @Input() canSave = true;
  @Input() nextInfo: nextInfoType = undefined;
  @Input() hideSecondaryColumn = false;
  @Input() hasAttachments = true;
  @Input() hasContentText = true;
  @Input() hasAttachmentsMandatory = false;
  @Input() addButtonTrad = 'DASHBOARD.MODAL.ADD_BUTTON';
  @Input() updateButtonTrad = 'DASHBOARD.MODAL.UPDATE_BUTTON';
  @Input() canUpdate = true;
  @Input() notifications: HolNotification[];
  @Input() historyItemComponent: ComponentType<any>;
  @Input() historyItemObjectId: string;
  @Input() historyItemType: string;
  @Input() historyTitle: string;
  @Input() historyItemListMode = false;
  @Input() canEditTitle = false;
  @Input() isHistory = false;
  @Input() isUTC = true;
  @Input() extendACLOnly: boolean;
  @Input() disabledValidateActions = false;
  @Input() notifyFunction: HolNotifyFunction = undefined;
  @Input() applicability = undefined;
  @Input() canUpdateFromOtherModule = false;
  @Input() fastMode = false;
  @Input() config$: Observable<HolModalConfig>;
  @Input() requiredPart: string[] = [];

  selectedTag: HolTag[];
  initialContentText: string;
  @Output() changeItems = new EventEmitter<HolModalConfig>();
  @Output() actionClicked = new EventEmitter<ButtonConfig>();
  @Output() saveItem = new EventEmitter<{
    contentTextChanged?: boolean;
    notifications?: HolNotification[];
    nextInfo?: nextInfoType;
    notifyFunction?: HolNotifyFunction;
  }>();
  @Output() archiveItem = new EventEmitter();
  @ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;
  @ViewChild('scrollTargetModalHol', { static: false }) scrollTarget: ElementRef;
  public isLoading = false;
  protected templateMap: Map<string, TemplateRef<Component>> = new Map();
  protected readonly module = module;
  protected readonly ErpCrisisTask = ErpCrisisTask;
  protected readonly Input = Input;
  private buttonAvailable: ButtonConfig[] = [];
  private holOptions: HOLOptions;
  public isNoteSaved: boolean = false;

  constructor(
    public moduleConfig: ModuleConfigService,
    private _ngZone: NgZone,
    public matDialog: MatDialog,
    dialogRef: MatDialogRef<HolModalComponent>,
    private cdr: ChangeDetectorRef,
    private currentUserService: CurrentUserService,
    public holOptionsService: HolOptionsService,
  ) {
    dialogRef.disableClose = true;
  }

  get isErpCrisisTask() {
    return this.config.item instanceof ErpCrisisTask ? !this.config.item.isTemporary : false;
  }

  get showNotifications(): boolean {
    return (
      !this.config.isReadOnly &&
      this.config.canUpdate &&
      ((this.config.notifications && this.config.notifications.length !== 0) || !!this.config.notifyFunction)
    );
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.matDialog.closeAll();
  }

  onPanelOpened() {
    this.cdr.detectChanges();
  }

  async ngOnInit() {
    this.isLoading = true;

    this.initTemplate();

    if (!this.config) {
      this.config = new HolModalConfigBuilder()
        .withItem(this.item)
        .setReadOnly(this.isReadOnly)
        .setCreate(this.isCreate)
        .setType(this.type)
        .setContext({
          module: this.context.module,
          category: this.context.category,
          htmlTitle: this.context.htmlTitle,
          htmlDate: this.context.htmlDate,
          htmlTemplate: this.context.htmlTemplate,
        })
        .setContentTextLenght(this.contentTextLength)
        .setCanUpdate(this.canUpdate)
        .setLimitTo(this.limitTo)
        .setCanArchive(this.canArchive)
        .setNotifications(this.notifications)
        .setHistoryItemComponent(this.historyItemComponent)
        .setHistoryItemObjectId(this.historyItemObjectId)
        .setHistoryItemType(this.historyItemType)
        .setHistoryTitle(this.historyTitle)
        .setCanEditTitle(this.canEditTitle)
        .setIsHistory(this.isHistory)
        .setForm(this.form ? this.form : new FormGroup({}))
        .build();

      // throw new Error('HolModalComponent::config is required');
    }
    if (!this.config.item) {
      throw new Error('HolModalComponent::item is required');
    }
    if (this.config.item.contentText === undefined) {
      throw new Error('HolModalComponent::item.contentText is required');
    }
    if (!this.config.type) {
      throw new Error('HolModalComponent::type is required');
    }
    if (!this.form) {
      this.form = new FormGroup({});
    }

    if (this.config$) {
      this.config$.subscribe(config => {
        this.config = config;
        if (!this.config.form) {
          this.config.form = this.form;
        }
        // Traitez les changements ici
        this.initialContentText = cloneDeep(this.config.item.contentText);
        if (this.config.notifications) {
          this.config.notifications = this.config.notifications.sort();
        }

        //Je récupère la liste dont j'ai besoin
        this.buttonAvailable = modalButtonConfig
          .filter(butConfig => {
            return (
              butConfig.access[this.context.category as ModalType].includes(this.context.module as ModuleType) &&
              butConfig.stateType.includes(this.isCreate ? 'CREATE' : 'UPDATE')
            );
          })
          .map(but => {
            if (but.type == 'TOGGLE') {
              let toggle: ButtonToggle = {
                ...but,
                value: false,
              };
              return toggle;
            } else if (but.type == 'PARAMETER') {
              let param: ButtonParameter = {
                ...but,
                isContentDisplay: false,
              };
              return param;
            }
            return but;
          });
        //Stock les buttonConfig dans une map avec le Name en KEY
        this.keyButton = this.buttonAvailable.reduce((map, buttonConfig) => {
          map.set(buttonConfig.name, buttonConfig);
          return map;
        }, new Map<string, ButtonConfig>());
        //Creer un groupe pour l'affichage par type
        // Le composant TAG est supprimé car il a un comportement spécial
        this.groupButton = groupBy(
          this.buttonAvailable.filter(value => value.name !== 'TAG'),
          item => item.type,
        );

        console.debug(this.groupButton);
        this.loadContent();

        this.isLoading = false;
      });
    } else {
      this.isLoading = true;
      //  throw new Error('HolModalComponent::config$ is required');
    }
  }

  ngOnDestroy() {}

  triggerResize() {
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  saveNextInfo(nextInfo: nextInfoType) {
    this.config.nextInfo.nextInfoTime = nextInfo.nextInfoTime;
    this.config.nextInfo.nextInfoDone = nextInfo.nextInfoDone;
  }

  saveNotifications(notifications: HolNotification[]) {
    this.config.notifications = cloneDeep(notifications);
  }

  saveNotifyFunction(notifyFunction: HolNotifyFunction) {
    this.config.notifyFunction = cloneDeep(notifyFunction);
  }

  openHistory() {
    const data: DialogHistoryData = {
      title: 'Dialog Title',
      historyItemComponent: this.config.historyItemComponent,
      historyItemObjectId: this.config.historyItemObjectId,
      historyItemType: this.config.historyItemType,
      historyTitle: this.config.historyTitle,
      historyItemListMode: this.config.historyItemListMode,
    };
    this.matDialog.open(HolHistoryItemsModalComponent, {
      data,
    });
  }

  save() {
    let contentText = false;
    if (
      this.config.canEditTitle &&
      this.initialContentText &&
      this.initialContentText.trim().length > 0 &&
      this.initialContentText !== this.config.item.contentText
    ) {
      contentText = true;
    }
    const objectToEmit: {
      contentTextChanged?: boolean;
      notifications?: HolNotification[];
      nextInfo?: nextInfoType;
      notifyFunction?: HolNotifyFunction;
    } = { contentTextChanged: contentText, notifications: this.config.notifications };
    if (this.config.notifyFunction) {
      objectToEmit.notifyFunction = this.config.notifyFunction;
    }
    if (!this.config.nextInfo) {
      this.saveItem.emit(objectToEmit);
    } else {
      if (!this.config.form.invalid && this.config.canSave) {
        objectToEmit.nextInfo = this.config.nextInfo;
        this.saveItem.emit(objectToEmit);
      }
    }
  }

  archive() {
    if (this.config.canArchive) {
      this.archiveItem.emit();
    }
  }

  updateTags(item) {
    const tags = item.isTemporary ? item['tags'] : item['defaultTags'];
    if (tags.length > 0) {
      this.config.disabledValidateActions = false;
    }
  }

  updatedAttachment() {
    if (this.config.context.module === 'ERP' && this.config.context.category === 'DOCUMENT') {
      const cond =
        !this.config.item.attachments ||
        (this.config.item.attachments &&
          !this.config.item.attachments.note &&
          !this.config.item.attachments.noteFile &&
          !this.config.item.attachments.file &&
          this.config.item.attachments.files.length === 0 &&
          !this.config.item.attachments.image &&
          !this.config.item.attachments.link);
      this.config.canSave = !cond;
    }
  }

  shouldDisplayButton(buttonName: string): boolean {
    return true;
    /*
     const configForCurrentModal = modalButtonConfig[this.context.category];
     const buttonConfig = configForCurrentModal.find(config => config.name === buttonName);
     return buttonConfig ? buttonConfig.modules.includes(this.context.module) : false;
     */
  }

  onButtonClick(buttonConfig: ButtonConfig) {
    switch (buttonConfig.type) {
      case 'ACTION':
        if (buttonConfig.name === 'NOTIFICATION') {
          // Implémentez la logique pour le type ACTION

          this.actionMode = !this.actionMode;
        } else {
          this.actionClicked.emit(buttonConfig);
        }
        break;
      case 'PARAMETER':
        // Implémentez la logique pour le type PARAMETER
        let paramButton: ButtonParameter;
        paramButton = buttonConfig as ButtonParameter;

        if (!(paramButton.name === 'ATTACHMENTS' && this.config.forceOpenAttachments)) {
          paramButton.isContentDisplay = !paramButton.isContentDisplay;
          const tempSection = this.sections.filter(section => section.key === buttonConfig.name);
          if (tempSection.length > 0 && tempSection[0]) {
            this.sections = this.sections.filter(section => section.key !== buttonConfig.name);
          } else {
            this.addItemOnSection(buttonConfig, true);
          }
        }
        break;
      case 'TOGGLE':
        // Implémentez la logique pour le type TOGGLE
        console.log('toggle');

        let toggleButton: ButtonToggle;
        toggleButton = buttonConfig as ButtonToggle;
        toggleButton.value = !toggleButton.value;

        switch (toggleButton.name) {
          case 'PIN':
            (this.config.item as IsPinned).isPinned = toggleButton.value;
            //   (this.item as IsPinned).isPinned = toggleButton.value;

            break;
          case 'CHECK_BOX':
            (this.config.item as Done).done = toggleButton.value;
            break;
          case 'TO_SUMMARY':
            this.config.isOnSummary = toggleButton.value;
            this.changeItems.emit(this.config);

            break;
          case 'TO_ERP':
            this.config.transfertToERP = toggleButton.value;
            this.changeItems.emit(this.config);
            break;

          case 'INTERNAL':
            this.config.isPrivate = toggleButton.value;
            this.changeItems.emit(this.config);
            break;
        }

        break;
      case 'TRANSFER':
        switch (buttonConfig.name) {
          case 'TO_DECISION':
            //TODO to decision
            this.config.transfertToDecision = true;
            this.changeItems.emit(this.config);
            break;
          case 'TO_LOGBOOK':
            //TODO to decision
            this.config.transfertToLogbook = true;
            this.changeItems.emit(this.config);
            break;
          case 'TO_ERP':
            //TODO to decision
            this.config.transfertToERP = true;
            this.changeItems.emit(this.config);
            break;
        }
        // Implémentez la logique pour le type TRANSFER
        break;
    }
  }

  isButtonToggled(buttonConfig: ButtonConfig | ButtonToggle): boolean {
    if (buttonConfig.type === 'TOGGLE') {
      return (buttonConfig as ButtonToggle).value;
    }
    return false;
  }

  isContentDisplay(buttonName: string): boolean {
    const section = this.sections.filter(section => section.key === buttonName);
    return section && section.length && section.length > 0;
  }

  toggleMode() {
    this.actionMode = !this.actionMode;
  }

  getNotificationActive() {
    return this.config.notifications.filter(notification => {
      return notification.sendByMail || notification.sendBySms;
    }).length;
  }

  private async loadContent() {
    if (
      (this.config.hasAttachments &&
        this.config.item.attachments &&
        this.config.item.attachments instanceof HolAttachments &&
        !this.config.item.attachments.isEmptyWithoutNote()) ||
      this.config.forceOpenAttachments
    ) {
      this.addItemOnSection(this.keyButton.get('ATTACHMENTS'));
    }

    if (!this.isCreate) {
      if (isIsPinned(this.config.item)) {
        let item = this.config.item as IsPinned;
        let but = this.keyButton.get('PIN');
        if (but) {
          (but as ButtonToggle).value = item.isPinned;
        }
      }
      if (isDone(this.config.item)) {
        let item = this.config.item as Done;
        let but = this.keyButton.get('CHECK_BOX');
        if (but) {
          (but as ButtonToggle).value = item.done;
        }
      }
      if (isInSummary(this.config.item)) {
        // @ts-ignore
        let item = this.item as InSummary;
        let but = this.keyButton.get('TO_SUMMARY');
        if (but) {
          (but as ButtonToggle).value = item.summary != null;
        }
      }
      if (isToErp(this.config.item)) {
        let item = this.config.item as ToErp;
        let but = this.keyButton.get('TO_ERP');
        if (but) {
          (but as ButtonToggle).value = item.toERP;
        }
      }
    }
    if (isInternal(this.config)) {
      let item = this.config as Internal;
      let but = this.keyButton.get('INTERNAL');
      if (but) {
        (but as ButtonToggle).value = this.config.isPrivate;
      }
    }
    // setTimeout(() => {
    //  console.log('timeout this.config.haveGroup', this.config.haveGroup);
    if (this.config.haveGroup) {
      this.addItemOnSection(this.keyButton.get('GROUP_LINK'));
    }
    if (this.config.haveDecisionLink) {
      this.addItemOnSection(this.keyButton.get('DECISION_LINK'));
    }
    if (this.config.haveFlightLink) {
      this.addItemOnSection(this.keyButton.get('FLIGHT_LINK'));
    }
    if (this.config.haveEventLink) {
      this.addItemOnSection(this.keyButton.get('EVENT_LINK'));
    }
    if (this.config.haveLogbookLink) {
      this.addItemOnSection(this.keyButton.get('LOGBOOK_LINK'));
    }
    if (this.config.nextInfo && this.config.nextInfo.nextInfoTime) {
      this.addItemOnSection(this.keyButton.get('TIMER_ALERT'));
    }

    //cleanup
    if (this.config.notifications) {
      this.config.notifications = this.config.notifications.map(notif => {
        notif.sendBySms = false;
        notif.sendByMail = false;
        return notif;
      });
    }

    this.holOptions = await this.holOptionsService.get(false);

    this.contentTextLength = this.holOptions.fieldDataLimit;
  }

  private initTemplate() {
    this.templateMap.set('workInProgress', this.workInProgress);
    this.templateMap.set('TAG', this.templateTAG);
    this.templateMap.set('ATTACHMENTS', this.templateATTAMENTS);
    this.templateMap.set('GROUP_LINK', this.templateGROUP_LINK);
    this.templateMap.set('DECISION_LINK', this.templateDECISION_LINK);
    this.templateMap.set('FLIGHT_LINK', this.templateFLIGHT_LINK);
    this.templateMap.set('EVENT_LINK', this.templateEVENT_LINK);
    this.templateMap.set('APPLICABILITY', this.templateAPPLICABILITY);
    this.templateMap.set('LOGBOOK_LINK', this.templateLOGBOOK_LINK);
    this.templateMap.set('TIMER_ALERT', this.templateTIMER_ALERT);
    this.templateMap.set('MAKE_TODO', this.templateMAKE_TODO);
  }

  private addItemOnSection(buttonConfig: ButtonConfig, withScroll: boolean = false) {
    if (this.sections.filter(section => section.key === buttonConfig.name).length == 0) {
      this.sections.push({
        key: buttonConfig.name,
        button: buttonConfig,
        template: this.templateMap.get(buttonConfig.name) || this.templateMap.get('workInProgress'),
      });

      if (withScroll) {
        setTimeout(() => {
          this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }, 500);
      }
    }
  }

  isNoteSave($event: boolean) {
    this.isNoteSaved = $event;
  }
}

export interface SectionConfig {
  key: string;
  button: ButtonConfig;
  template: TemplateRef<any>; // une référence de template
}
